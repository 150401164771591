import { ACCESS_TOKEN, config, merchantId } from "../config/Config";
import axios from "axios";

// -- Axios

const instance = axios.create({
  baseURL: config.url.API_BASE_URL,
});
const gateWayInstance = axios.create({
  baseURL: "/hermes",
});





export function retrieveClientUser(clientUserName) {
  return instance.get("/secure/finance/userData/"+clientUserName, {
    headers: {
      "Content-type": "application/json",
      Authorization: localStorage.getItem(ACCESS_TOKEN)
        ? "Bearer " + localStorage.getItem(ACCESS_TOKEN)
        : null,
    },
  });
}
export function createManualSubscription(subscriptionRequest) {
  return instance.post("/secure/finance/addManualPayment", subscriptionRequest,{
    headers: {
      "Content-type": "application/json",
      Authorization: localStorage.getItem(ACCESS_TOKEN)
        ? "Bearer " + localStorage.getItem(ACCESS_TOKEN)
        : null,
    },
  });
  
}
export function updatePaymentStatusBackend(invoiceStatusRequest) {
  return instance.post("/gvg/secure/admin/invoiceStatusUpdate", invoiceStatusRequest,{
    headers: {
      "Content-type": "application/json",
      Authorization: localStorage.getItem(ACCESS_TOKEN)
        ? "Bearer " + localStorage.getItem(ACCESS_TOKEN)
        : null,
    },
  });
}