import * as React from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Page,
  Toolbar,
  Edit,
  Inject,
} from "@syncfusion/ej2-react-grids";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import "./grid.css";
import { toast, Toast } from "react-hot-toast";
import { retrieveGvgPlan } from "../../api/PupilApi";
import { IoCloudDownload } from "react-icons/io5";
import AdminViewAction from "./AdminViewAction";


function AdminViewPlanList() {
    const navigate = useNavigate();
    const location = useLocation();
    const { planName, planCost, planDes,planInvoiceList,email,name,planId } = location.state; // constrcut as planData
    console.log(planInvoiceList);
  const [loading,setLoading] = useState(false);
  const [data, setData] = useState([]);
  const toolbarOptions = ["Search"];
 
  const validationRules = { required: true };
  const orderidRules = { required: true, number: true };
  const pageSettings = { pageCount: 5 };
 
  useEffect(() => {
    setData(planInvoiceList);
  }, []);
  // const loadData = () => {
  //   setLoading(true)
  //   retrieveGvgPlan(email)
  //     .then((res) => {
  //      // alert("day"+JSON.stringify(res));
  //       toast.success(res.data.message)
  //     setData(res.data);
  //       setLoading(false);
  //     })
  //     .catch((error) => {
  //       if (error.message) {
  //           toast.error(error.message);
  //           toast.error("error");
  //       } else {
  //           toast.error('Sorry! Something went wrong. Please try again!');
  //           toast.error("error");
  //       }
  //       setLoading(false)
  //       console.log(error);
  //     });
  //     setLoading(false);
  // }
  const handleClickBack = () => {
    navigate("/adminDashboard");
  };
  const actionTemplateToViewPaymentList = (props) => {
    return (
      <Link
        to={{
          pathname: "/viewPlanInvoiceList",
        }}
        state={{
          name: props.name,
          email: props.email,
          planList: props.planList,
        }}
        exact
      >
        <button
          className="px-2 text-sm border-1 border-brightColor bg-backgroundColor              
                                            hover:text-brightColor transition-all rounded-lg font-bold uppercase "
          type="button"
        >
          {" "}
          <span>
            <IoCloudDownload />
          </span>{" "}
        </button>
      </Link>
    );
  };
  const paymentTemplate = (props) => {
   

    return <AdminViewAction planName={planName}  planCost={planCost} email={email} name={name} planId={planId} paymentInvoiceId={props.paymentInvoiceId}/>;

  };
  return (
    <div className="control-pane flex-1 m-5">
      <div className="control-section">
      <div className="flex items-center mb-3">
        <h2 className="name pr-3 font-medium">Plan description:<span className="bg-dark font-semibold">{planDes}</span></h2>
        <button
          className="select-none rounded-lg float-right bg-black text-white py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase shadow-md shadow-pink-500/20 transition-all hover:shadow-lg hover:shadow-pink-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
          onClick={() => handleClickBack()}
        >
          <span>back</span>
        </button>
        </div>
        
        <GridComponent
          dataSource={data}
          toolbar={toolbarOptions}
          allowPaging={true}
          pageSettings={pageSettings}
        >
          <ColumnsDirective>
         <ColumnDirective
              field="action"
              headerText="Action"
              width="100"
              textAlign="Center"
              template={paymentTemplate}
            ></ColumnDirective>
             
             <ColumnDirective
              field="paymentInvoiceId"
              headerText="Payment Id"
              width="100"
              textAlign="Center"
              validationRules={validationRules}
            ></ColumnDirective>
            <ColumnDirective
              field="costExcludingGstPay"
              headerText="Cost"
              width="100"
              textAlign="Center"
              validationRules={validationRules}
            ></ColumnDirective>
            <ColumnDirective
              field="costIncludingGstPay"
              headerText="Cost+Gst"
              width="100"
              textAlign="Center"
            ></ColumnDirective>
            <ColumnDirective
              field="dueDate"
              headerText="Due Date"
              width="100"
              textAlign="Center"
            ></ColumnDirective>
          
            <ColumnDirective
              field="invoiceDate"
              headerText="Invoice Date"
              width="100"
              textAlign="Center"
            ></ColumnDirective>
              <ColumnDirective
              field="paymentStatus"
              headerText="Payment Status"
              width="100"
              textAlign="Center"
            ></ColumnDirective>
        <ColumnDirective
              field="transactionDetails.transactionDate"
              headerText="Transaction Date"
              width="100"
              textAlign="Center"
            ></ColumnDirective>
       
          </ColumnsDirective>
          <Inject services={[Page, Toolbar, Edit]} />
        </GridComponent>
      </div>
    </div>
  );
        };

export default AdminViewPlanList;
