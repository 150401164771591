import React from "react";
import { Link } from "react-router-dom";
import Breadcrumbs from "../../components/pageProps/Breadcrumbs";

const About = () => {
  return (
    <div className="max-w-container mx-auto px-4">
      <Breadcrumbs title="About" />
      <div className="pb-10 text-center">
        <h1 className="text-3xl font-semibold text-primeColor mb-6">
          Welcome to Our Silver Jewelry World
        </h1>
        <p className="text-base text-lightText mb-4">
          At Silver Elegance, we are passionate about crafting exquisite silver jewelry that reflects timeless beauty and elegance. Each piece is meticulously designed to capture the essence of sophistication and style.
        </p>
        <p className="text-base text-lightText mb-4">
          Our commitment to quality craftsmanship ensures that every silver jewelry piece you choose is not just an accessory but a statement of your unique style and taste.
        </p>
        <p className="text-base text-lightText mb-4">
          Explore our collection and discover the perfect silver jewelry that complements your individuality.
        </p>
        <div className="mb-8">
          <p className="text-base text-lightText">Visit us at:</p>
          <address className="text-base text-lightText">
            33/A8, A9 Sree Devi Complex, Omaluran Street, Vijaya Mahal Near, Mecheri, Salem, Tamilnadu, India - 636453
          </address>
        </div>
        <p className="text-base text-lightText mb-8">
          For any inquiries, please contact us at Phone: 04298 278595, Mobile: 8903050494.
        </p>

        <Link to="/shop">
        <button className="w-52 h-10 bg-blue-500 text-white hover:bg-black duration-300">
            Explore Our Collection
          </button>
        </Link>
      </div>
    </div>
  );
};

export default About;
