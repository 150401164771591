
import { Link } from "react-router-dom";


const Chit = () => {
  {/*const location = useLocation();
  const [prevLocation, setPrevLocation] = useState("");


  useEffect(() => {
    setPrevLocation(location.state.data);
  }, [location]);
*/}
  const goldChitSchemes = [
    {
      id: 1,
      name: "Scheme 1",
      cost: 1250,
      description:
        "Monthly pay 1250 up to 12 months Benefits : <strong>3 gram KDM 916 hallmark Gold coin</strong> (or) <strong>2 Gram  KDM 916 hallmark gold coin and 50 gram silver jewells or silver coin</strong>",
    },
    {
      id: 2,
      name: "Scheme 2",
      cost: 2500,
      description:
        "Monthly pay 2500 up to 12 months Benefits : <strong>6 gram KDM 916 hallmark Gold coin</strong> (or) <strong>4 Gram  KDM 916 hallmark gold coin and 120 gram silver jewells or silver coin</strong>",
    },
    {
      id: 3,
      name: "Scheme 3",
      cost: 5000,
      description:
        "Monthly pay 5000 up to 12 months Benefits : <strong>12 gram KDM 916 hallmark Gold coin</strong> (or) <strong>8 Gram  KDM 916 hallmark gold coin and 250 gram silver jewells or silver coin</strong>",
    },
  ];

  const generalRulesList = [
    "Customers monthly pay Rs. 1250/- Rs.2500/- Rs.5000 as per plan",
    "Pay installment every month between 1-5 date",
    "After completing 12 installments, we provide a KDM 916 gold coin as per the scheme.",
    "If any customers not pay correctly 12 installments, they will get total paid amount at the end of 12th month with deduction of 1 month installment for member fee",
    "If customers continuously not pay two installments as per company policy customer disqualified from the chit scheme.",
    "After successful completion of 12th installment, then customer get the gold coin or silver coin with in 30 days.",
  ];

  return (
    <div className="max-w-container mx-auto px-4">
      {/*<Breadcrumbs title="Chits" prevLocation={prevLocation} />*/}
      <div className="pb-4 mt-8">
        <div className="bg-blue-300 text-black p-4 mb-4">
          <strong>General Rules:</strong>
          <ul className="list-disc pl-4">
            {generalRulesList.map((rule, index) => (
              <li key={index}>{rule}</li>
            ))}
          </ul>
        </div>
      </div>
      <div className="flex flex-col gap-4 md:flex-row md:flex-wrap">
        {goldChitSchemes.map((scheme) => (
          <div
            key={scheme.id}
            className={`w-full md:w-1/2 lg:w-1/3 xl:w-1/4 bg-white p-6 rounded-md shadow-md mb-4 ${
              scheme.name === "Scheme 1" ? "bg-yellow-200" : ""
            } ${
              scheme.name === "Scheme 2" ? "bg-green-200" : ""
            } ${
              scheme.name === "Scheme 3" ? "bg-blue-200" : ""
            }`}
          >
            <div>
              <span className="text-primeColor font-semibold text-lg">{scheme.name}</span>
            </div>
            <div className="mt-2">
              <p
                className="text-base text-lightText"
                dangerouslySetInnerHTML={{ __html: scheme.description }}
              ></p>
              <Link to={"/plans"} 
              state={{
                planType: "Monthly",
                planDes: scheme.description,
                planDuration: "12",
                planName: scheme.name,
                planStatus: "Init",
                planCost: scheme.cost,

              }}>
                <button
                  style={{ backgroundColor: "#007bff" }}
                  className="w-full md:w-32 h-10 text-white hover:bg-black duration-300 mt-2"
                >
                  Choose Plan
                </button>
              </Link>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Chit;
