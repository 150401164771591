import React from 'react';
import { useContextSelector } from 'use-context-selector';
import { StateContext } from '../../contexts/StateContext';
import { saveGoldLoanDataBff } from '../../api/PupilApi';
import toast, { Toaster } from "react-hot-toast";
import { Link,useNavigate } from "react-router-dom";
const FixedDepositPage = () => {
  const navigate=useNavigate();
  const isLoggedIn = useContextSelector(StateContext, (state) => state.isLoggedIn);
  const saveFixedRequest = (e) => {
    
    //alert("onclick inside"+JSON.stringify(products));
    e.preventDefault();

    if(isLoggedIn){ 
       // alert("before backend call"+JSON.stringify(orderRequest));
       saveGoldLoanDataBff("fdRequest")
          .then((res) => {
           // alert("after backend call"+JSON.stringify(res));
           toast(res.message);
           navigate("/fixeddepositsucess");
          })
          .catch((error) => {
            if (error.message) {
              toast(error.message);
              
            } else {
              toast("Sorry! Something went wrong. Please try again!");
          
              
            }
           // setShowLoading(false);
            console.log(error);
          });}
          else{
           
        navigate("/signup")

          }
   
           
    
  };
  
  return (
    
    <div className="fixed-deposit-page p-4 md:p-10 bg-gradient-to-b from-gray-100 to-gray-200">
      <div className="mb-6 text-center">
        <h1 className="text-4xl font-bold text-gray-800">Fixed Deposit Schemes</h1>
        <h1 className="text-4xl font-bold text-gray-800">Fixed Deposit Gold Schemes</h1>
        <div className="col-lg-12 flex justify-center mt-6">
          <a
           onClick={saveFixedRequest}
            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700 transition duration-300"
          >
            Request
          </a>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {/* 3-Month Scheme */}
        <div className="bg-white border rounded p-4 shadow-md">
          <h2 className="text-2xl font-bold mb-2">3-Month Scheme</h2>
          <p>Interest Rate: <span className="text-blue-500">10%</span> per annum</p>
          <p>Tenure: 3 months</p>
          <p>Get interest 4 times per year</p>
          <p>Interest calculation per 3 months as <span className="text-green-500">(10/4=2.5%)</span></p>
        </div>

        {/* 3-Month Scheme (Jewels) */}
        <div className="bg-white border rounded p-4 shadow-md">
          <h2 className="text-2xl font-bold mb-2">3-Month Scheme (Jewels)</h2>
          <p>Interest Rate: <span className="text-blue-500">5%</span> per annum</p>
          <p>Tenure: 3 months</p>
        </div>

        {/* 6-Month Scheme */}
        <div className="bg-white border rounded p-4 shadow-md">
          <h2 className="text-2xl font-bold mb-2">6-Month Scheme</h2>
          <p>Interest Rate: <span className="text-blue-500">12%</span> per annum</p>
          <p>Tenure: 6 months</p>
          <p>Get interest 2 times per year</p>
          <p>Interest calculation per 2 months as <span className="text-green-500">(12/2=6%)</span></p>
        </div>

        {/* 6-Month Scheme (Jewels) */}
        <div className="bg-white border rounded p-4 shadow-md">
          <h2 className="text-2xl font-bold mb-2">6-Month Scheme (Jewels)</h2>
          <p>Interest Rate: <span className="text-blue-500">6%</span> per annum</p>
          <p>Tenure: 6 months</p>
        </div>

        {/* 1-Year Scheme */}
        <div className="bg-white border rounded p-4 shadow-md">
          <h2 className="text-2xl font-bold mb-2">1-Year Scheme</h2>
          <p>Interest Rate: <span className="text-blue-500">14%</span> per annum</p>
          <p>Tenure: 1 year</p>
          <p>Get interest 1 time per year</p>
          <p>Interest calculation year <span className="text-green-500">14%</span></p>
        </div>

        {/* 1-Year Scheme (Jewels) */}
        <div className="bg-white border rounded p-4 shadow-md">
          <h2 className="text-2xl font-bold mb-2">1-Year Scheme (Jewels)</h2>
          <p>Interest Rate: <span className="text-blue-500">8%</span> per annum</p>
          <p>Tenure: 1 year</p>
        </div>
      </div>

      <p className="text-gray-600 text-center mt-4">Note: Interest rates and minimum deposit amounts are subject to change.</p>
    </div>
  );
};

export default FixedDepositPage;
