import React, { useState } from "react";
import { Link, useLocation, useNavigate, Navigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { savePlanDataBff } from "../../api/PupilApi";
import { useContextSelector } from "use-context-selector";
import { StateContext } from "../../contexts/StateContext";
import { GoTriangleDown } from "react-icons/go";

const SignupForm = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { planType, planDes, planDuration, planCost, planName, planStatus } =
    location.state; // constrcut as planData
  const isLoggedIn = useContextSelector(
    StateContext,
    (state) => state.isLoggedIn
  );
  const setIsLoggedIn = useContextSelector(
    StateContext,
    (state) => state.setIsLoggedIn
  );
  const [chitCount, setChitCount] = useState(1);
  const [planRequest, setPlanRequest] = useState({
    planData: {
      planType:planType,
      planDes: planDes,
      planDuration:planDuration,
      planName: planName,
      planStatus: planStatus,
      planCost: planCost,
    },
    chitCount: 1,
  })
  const [formData, setFormData] = useState({
    username: "",
    email: "",
    mobile: "",
    scheme1: false,
    scheme2: false,
    scheme3: false,
    planType: planType,
    planDes: planDes,
    planDuration: planDuration,
  });
  const [errorMessage, setErrorMessage] = useState(
    "You are not logged in please signup or login."
  );
  const schemePrices = {
    scheme1: 1250,
    scheme2: 2500,
    scheme3: 5000,
  };
  {
    /*
  alert("planDescription"+JSON.stringify(planDes))
  alert("plantype"+JSON.stringify(planType))
  alert("planDuration"+JSON.stringify(planDuration))
*/
  }
  const handleChange = (e) => {
    const { name, type, checked, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const calculateTotalAmount = () => {
    //alert("data"+planCost+ chitCount)
    return planCost*chitCount;
    {/*return Object.keys(formData)
      .filter((key) => formData[key] && schemePrices[key])
      .reduce((total, key) => total + schemePrices[key], 0);
  */}
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log({ ...formData, totalAmount: calculateTotalAmount() });
  };

  const planOrderData = (e) => {
   // alert("onclick inside" + JSON.stringify(formData));
    e.preventDefault();
    planRequest.chitCount = chitCount;  
    //alert("before backend call" + JSON.stringify(planRequest));
    savePlanDataBff(planRequest)
      .then((res) => {
      //  alert("after backend call" + JSON.stringify(res));
        toast(res.message);
        navigate("/plansuccess");
      })
      .catch((error) => {
        if (error.message) {
          toast(error.message);
        } else {
          toast("Sorry! Something went wrong. Please try again!");
        }
        // setShowLoading(false);
        console.log(error);
      });
  };
  if (isLoggedIn) {
    return (
      <div className="container mx-auto mt-6 flex justify-center items-center">
        <div className="bg-white shadow-md p-8 rounded-md w-full max-w-md">
          <h2 className="text-2xl font-semibold mb-4">Chit Scheme</h2>
          <form onSubmit={handleSubmit}>
            {/** <div className="flex flex-col gap-.5">
            <p className="font-titleFont text-base font-semibold text-gray-600">
              Full Name:
            </p>
            <input
              type="text"
              id="username"
              name="username"
              value={formData.username}
              onChange={handleChange}
              className="w-full h-8 placeholder:text-sm placeholder:tracking-wide px-4 text-base font-medium placeholder:font-normal rounded-md border-[1px] border-gray-400 outline-none"
              required
            />
          </div>
          <div className="flex flex-col gap-.5">
            <p className="font-titleFont text-base font-semibold text-gray-600">
              Email:
            </p>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="w-full h-8 placeholder:text-sm placeholder:tracking-wide px-4 text-base font-medium placeholder:font-normal rounded-md border-[1px] border-gray-400 outline-none"
              required
            />
          </div>
          <div className="flex flex-col gap-.5">
            <p className="font-titleFont text-base font-semibold text-gray-600">
              Mobile Number:
            </p>
            <input
              type="tel"
              id="mobile"
              name="mobile"
              value={formData.mobile}
              onChange={handleChange}
              className="w-full h-8 placeholder:text-sm placeholder:tracking-wide px-4 text-base font-medium placeholder:font-normal rounded-md border-[1px] border-gray-400 outline-none"
              required
            />
          </div>*/}
            <div className="mb-2">
              {" "}
              {/** how many gold chit */}
              <div className="flex items-center gap-2 text-[#767676] relative">
                <label className="block">Select number of Chit:</label>
                <select
                  onChange={(event) => setChitCount(event.target.value)}
                  id="countries"
                  className="w-16 md:w-20 border-[1px] border-gray-200 py-1 px-4 cursor-pointer text-primeColor text-base block dark:placeholder-gray-400 appearance-none focus-within:outline-none focus-visible:border-primeColor"
                >
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                </select>
               {/*  <span className="absolute text-sm right-28 top-2.5">
                  <GoTriangleDown />
                </span>
                */}
              </div>
              
            </div>
            
            <div className="flex flex-col gap-.5">
              <p className="font-titleFont text-base font-semibold text-gray-600">
                Total Amount:
              </p>
              <input
                type="text"
                id="totalAmount"
                name="totalAmount"
                value={calculateTotalAmount()}
                className="w-full h-8 placeholder:text-sm placeholder:tracking-wide px-4 text-base font-medium placeholder:font-normal rounded-md border-[1px] border-gray-400 outline-none"
                readOnly
              />
            </div>

            <button
              onClick={planOrderData}
              className="w-full mt-3 text-white bg-blue-500 text-base font-medium h-10 rounded-md hover:bg-blue-700 hover:text-white duration-300"
            >
              Buy Plan
            </button>
          </form>
        </div>
        <Toaster
          position="bottom-right"
          reverseOrder={false}
          toastOptions={{
            error: {
              duration: 6000,
              style: {
                background: "#FF4136",
                color: "#fff",
              },
            },

            success: {
              duration: 6000,
              style: {
                background: "#008000",
                color: "#fff",
              },
            },
          }}
        />
      </div>
    );
  } else {
    return <Navigate to={"/signup/" + errorMessage} replace />;
  }
};

export default SignupForm;
