import {
  spfOne,
  spfTwo,
  spfThree,
  spfFour,
  bestSellerOne,
  bestSellerTwo,
  bestSellerThree,
  bestSellerFour,
  newArrOne,
  newArrTwo,
  newArrThree,
  newArrFour,
} from "../assets/images/index";

  
// =================== NavBarList Start here ====================

export const navBarList = [
  {
    _id: 1001,
    title: "Home",
    link: "/",
  },
  {
    _id: 1006,
    title: "Chit",
    link: "/Chit",
  },
  {
    _id: 1006,
    title: "FD",
    link: "/fixeddeposit",
  },
  {
    _id: 1005,
    title: "Goldloan",
    link: "/goldLoan",
  },
  {
    _id: 1002,
    title: "Shop",
    link: "/shop",
  },
  {
    _id: 1003,
    title: "About",
    link: "/about",
  },
  {
    _id: 1004,
    title: "Contact",
    link: "contact",
  },
  
  
];
// =================== NavBarList End here ======================
// =================== Special Offer data Start here ============
export const SplOfferData = [
 
  {
    _id: "201",
    img: spfOne,
    category: "Anklets",
    productName: "Anklet",
    price: "35.00",
    color: "White",
    size: "6 inch",
    weight: "20 gram",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },
  {
    _id: "202",
    img: newArrFour,
    category: "Anklets",
    productName: "Anklets",
    price: "180.00",
    color: "weight",
    size: "12 inch",
    weight: "70 gram",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },
  {
    _id: "203",
    img: spfThree,
    category: "Toe Rings",
    productName: "Toe Rings",
    price: "25.00",
    color: "Mixed",
    size: "11 inch",
    weight: "52 gram",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },
  {
    _id: "204",
    img: spfFour,
    category: "Anklets",
    productName: "Anklets",
    price: "220.00",
    color: "Black",
    size: "6.9 inch",
    weight: "81 gram",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },
];
// =================== Special Offer data End here ==============

// =================== PaginationItems Start here ===============

export const paginationItems = [
  {
    _id: 1001,
    img: spfOne,
    category: "Anklets",
    productName: "Anklet1",
    price: "35.00",
    color: "Black and White",
    size: "6 inch",
    weight: "37 gram",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },
  {
    _id: 1002,
    img: spfTwo,
    category: "Anklets",
    productName: "Anklets",
    price: "180.00",
    color: "Gray",
    size: "5.5 inch",
    weight: "80 gram",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },
  {
    _id: 1003,
    img: spfThree,
    category: "Toe Rings",
    productName: "Toe Rings",
    price: "25.00",
    color: "Mixed",
    size: "7 inch",
    weight: "67 gram",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },
  {
    _id: 1004,
    img: spfFour,
    category: "Anklets",
    productName: "Anklets",
    price: "220.00",
    color: "Black",
    size: "9 inch",
    weight: "78 gram",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },
  
  
  {
    _id: 1009,
    img: newArrOne,
    category: "Bracelets",
    productName: "Bracelets",
    price: "44.00",
    color: "Black",
    size: "8 inch",
    weight: "126 gram",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },
  {
    _id: 1010,
    img: newArrTwo,
    category: "Bracelets",
    productName: "Smart Watch",
    price: "250.00",
    color: "Black",
    size: "12.6 inch",
    weight: "99 gram",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },
  {
    _id: 1011,
    img: newArrThree,
    category: "Bracelets",
    productName: "Bracelets",
    price: "80.00",
    color: "Mixed",
    size: "8 inch",
    weight: "75 gram",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },
  {
    _id: 1012,
    img: newArrFour,
    category: "Bracelets",
    productName: "Mens Bracelet",
    price: "60.00",
    color: "Mixed",
    size: "5 inch",
    weight: "34 gram",
    badge: false,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },
  {
    _id: 1013,
    img: newArrTwo,
    category: "Bracelets",
    productName: "Bracelets for men",
    price: "60.00",
    color: "Mixed",
    size: "7 inch",
    weight: "23 gram",
    badge: false,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },

  {
    _id: 1014,
    img: newArrTwo,
    category: "Bracelets",
    productName: "Bracelets ",
    price: "250.00",
    color: "Black",
    size: "4 inch",
    weight: "95 gram",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },
  {
    _id: 1015,
    img: newArrFour,
    category: "Bracelets",
    productName: "Bracelets",
    price: "60.00",
    color: "Mixed",
    size: "5.5 inch",
    weight: "87 gram",
    badge: false,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },
  {
    _id: 1016,
    img: newArrTwo,
    category: "Bracelets",
    productName: "Bracelets",
    price: "250.00",
    color: "Black",
    size: "6 inch",
    weight: "53 gram",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },

  {
    _id: 1018,
    img: newArrOne,
    category: "Bracelets",
    productName: "Bracelets",
    price: "44.00",
    color: "Black",
    size: "6.6 inch",
    weight: "34 gram",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },
  {
    _id: 1019,
    img: newArrTwo,
    category: "Bracelets",
    productName: "Bracelets",
    price: "250.00",
    color: "Black",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },
  {
    _id: 1020,
    img: newArrThree,
    category: "Bracelets",
    productName: "Bracelets",
    price: "80.00",
    color: "Mixed",
    size: "9.2 inch",
    weight: "78 gram",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },
  {
    _id: 1021,
    img: spfThree,
    category: "Bracelets",
    productName: "Bracelets",
    price: "25.00",
    color: "Mixed",
    size: "7.3 inch",
    weight: "54 gram",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },
  {
    _id: 1022,
    img: spfFour,
    category: "Anklets",
    productName: "Anklets",
    price: "220.00",
    color: "Black",
    size: "1 inch",
    weight: "133 gram",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },
  
  {
    _id: 1024,
    img: spfOne,
    category: "Anklets",
    productName: "Anklet2",
    price: "35.00",
    color: "Black and White",
    size: "12 inch",
    weight: "144 gram",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },
  {
    _id: 1025,
    img: spfTwo,
    category: "Anklets",
    productName: "Anklets",
    price: "180.00",
    color: "Gray",
    size: "11.4 inch",
    weight: "153 gram",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },
  {
    _id: 1026,
    img: spfThree,
    category: "Toe Rings",
    productName: "Toe Rings",
    price: "25.00",
    color: "Mixed",
    size: "6 inch",
    weight: "53 gram",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },
  {
    _id: 1027,
    img: spfFour,
    category: "Anklets",
    productName: "Anklets",
    price: "220.00",
    color: "Black",
    size: "67.9inch",
    weight: "223 gram",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },
  {
    _id: 1032,
    img: newArrOne,
    category: "Braclets",
    productName: "Bracelets",
    price: "44.00",
    color: "Black",
    size: "6 inch",
    weight: "59 gram",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },
  {
    _id: 1033,
    img: newArrTwo,
    category: "Braclets",
    productName: "Bracelets",
    price: "250.00",
    color: "Black",
    size: "6.1 inch",
    weight: "52 gram",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },
  {
    _id: 1034,
    img: newArrThree,
    category: "Braclets",
    productName: "Bracelets",
    price: "80.00",
    color: "Mixed",
    size: "7.5 inch",
    weight: "83 gram",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },
  {
    _id: 1035,
    img: newArrFour,
    category: "Braclets",
    productName: "Bracelets",
    price: "60.00",
    color: "Mixed",
    size: "6 inch",
    weight: "69 gram",
    badge: false,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },
  {
    _id: 1036,
    img: newArrTwo,
    category: "Braclets",
    productName: "Bracelets",
    price: "60.00",
    color: "Mixed",
    size: "11.2 inch",
    weight: "232 gram",
    badge: false,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },
  {
    _id: 1037,
    img: newArrFour,
    category: "Braclets",
    productName: "Bracelets",
    price: "60.00",
    color: "Mixed",
    size: "9 inch",
    weight: "138 gram",
    badge: false,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },
  {
    _id: 1038,
    img: newArrTwo,
    category: "Braclets",
    productName: "Braclets",
    price: "250.00",
    color: "Black",
    size: "7.3 inch",
    weight: "223 gram",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },
  {
    _id: 1040,
    img: newArrOne,
    category: "Braclets",
    productName: "Bracelets",
    price: "44.00",
    color: "Black",
    size: "9 inch",
    weight: "123 gram",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },
  {
    _id: 1041,
    img: newArrTwo,
    category: "Braclets",
    productName: "Bracelets",
    price: "250.00",
    color: "Black",
    size: "8 inch",
    weight: "163 gram",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },
  {
    _id: 1042,
    img: newArrThree,
    category: "Braclets",
    productName: "Bracelets",
    price: "80.00",
    color: "Mixed",
    size: "6.4 inch",
    weight: "122 gram",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },
  {
    _id: 1043,
    img: spfThree,
    category: "Toe Rings",
    productName: "Toe Rings",
    price: "25.00",
    color: "Mixed",
    size: "6 inch",
    weight: "53 gram",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },
  {
    _id: 1044,
    img: spfFour,
    category: "Braclets",
    productName: "Bracelets",
    price: "220.00",
    color: "Black",
    size: "6 inch",
    weight: "53 gram",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },
  {
    _id: 1046,
    img: spfOne,
    category: "Anklets",
    productName: "Anklet3",
    price: "35.00",
    color: "Black and White",
    size: "6 inch",
    weight: "66 gram",
    badge: true,
    des: "Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis.",
  },
];
// =================== PaginationItems End here =================
