import axios from 'axios';
import {
  ACCESS_TOKEN,
  config,
} from '../config/Config';

// -- Axios

const instance = axios.create({
  baseURL: config.url.API_BASE_URL,
});

export function saveOrderDataBff(orderRequest) {
  return instance.post("/gvg/order/placeOrder", orderRequest,{
    headers: {
      "Content-type": "application/json",
      Authorization: localStorage.getItem(ACCESS_TOKEN)
        ? "Bearer " + localStorage.getItem(ACCESS_TOKEN)
        : null,
    },
    
  });
  
}
export function saveGoldLoanDataBff(loanRequest) {
  return instance.put("/gvg/common/updateAppliedFor", loanRequest,{
    headers: {
      "Content-type": "application/json",
      Authorization: localStorage.getItem(ACCESS_TOKEN)
        ? "Bearer " + localStorage.getItem(ACCESS_TOKEN)
        : null,
    },
    
  });
  
}
export function retrieveGvgOrder(email) {
  // alert("userName!!!"+clientUserName)
  return instance.get(`/gvg/order/orders/${email}`, {
    headers: {
      'Content-type': 'application/json',
      Authorization: localStorage.getItem(ACCESS_TOKEN)
        ? `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`
        : null,
    },
  });
}
export function retrieveGvgPlan(email) {
  // alert("userName!!!"+clientUserName)
  return instance.get(`/gvg/plan/plan/${email}`, {
    headers: {
      'Content-type': 'application/json',
      Authorization: localStorage.getItem(ACCESS_TOKEN)
        ? `Bearer ${localStorage.getItem(ACCESS_TOKEN)}`
        : null,
    },
  });
}
export function savePlanDataBff(orderRequest) {
  return instance.post("gvg/plan/choosePlan", orderRequest,{
    headers: {
      "Content-type": "application/json",
      Authorization: localStorage.getItem(ACCESS_TOKEN)
        ? "Bearer " + localStorage.getItem(ACCESS_TOKEN)
        : null,
    },
    
  });
  
}
export function saveGoldLoanData(loanRequest) {
  return instance.post("gvg/plan/choosePlan", loanRequest,{
    headers: {
      "Content-type": "application/json",
      Authorization: localStorage.getItem(ACCESS_TOKEN)
        ? "Bearer " + localStorage.getItem(ACCESS_TOKEN)
        : null,
    },
    
  });
  
}
export function retrieveUserData() {
  return instance.get("gvg/order/dashboard/pupil/order", {
    headers: {
      "Content-type": "application/json",
      Authorization: localStorage.getItem(ACCESS_TOKEN)
        ? "Bearer " + localStorage.getItem(ACCESS_TOKEN)
        : null,
    },
  });
}

export function getGvgOrder() {
 
  return instance.get(
    `/gvg/secure/admin/dashboard/users/order`,
    {
      headers: {
        'Content-type': 'application/json',
        Authorization: localStorage.getItem(ACCESS_TOKEN) ? `Bearer ${localStorage.getItem(ACCESS_TOKEN)}` : null,
      },
    },
  );
}
export function getGvgData() {
 
  return instance.get(
    `/gvg/secure/admin/dashboard/users/gvg`,
    {
      headers: {
        'Content-type': 'application/json',
        Authorization: localStorage.getItem(ACCESS_TOKEN) ? `Bearer ${localStorage.getItem(ACCESS_TOKEN)}` : null,
      },
    },
  );
}
export function getGvgPlan() {
 
  return instance.get(
    `/gvg/secure/admin/dashboard/users/plan`,
    {
      headers: {
        'Content-type': 'application/json',
        Authorization: localStorage.getItem(ACCESS_TOKEN) ? `Bearer ${localStorage.getItem(ACCESS_TOKEN)}` : null,
      },
    },
  );
}
export function getGvgOrderPlan() {
 
  return instance.get(
    `/gvg/secure/admin/dashboard/users/orderAndPlan`,
    {
      headers: {
        'Content-type': 'application/json',
        Authorization: localStorage.getItem(ACCESS_TOKEN) ? `Bearer ${localStorage.getItem(ACCESS_TOKEN)}` : null,
      },
    },
  );
}