import React from "react";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import successImage from "../../assets/images/successImage.png"; // You need to import your success image

const GoldLoanSuccess = () => {
  
  
  return (
    <div className="max-w-container mx-auto px-4">
      <motion.div
        initial={{ y: 30, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.4 }}
        className="flex flex-col justify-center items-center gap-4 mt-10"
      >
        <div>
          <img
            className="w-full md:w-80 rounded-lg p-4 mx-auto"
            src={successImage} // Replace with your success image
            alt="orderSuccess"
          />
        </div>
        <div className="max-w-[500px] w-full p-4 py-8 bg-white flex flex-col items-center rounded-md shadow-lg">
          <h1 className="text-xl md:text-2xl  text-center font-bold uppercase">
            Your Fixed Deposit Request Accepted successfully!
          </h1>
          <p className="text-sm md:text-base text-center px-4 md:px-10 -mt-2">
            Thank you for intrest GVG Fixed Deposit Schemes. Your Request has been accepted
            successfully. We will contact shortly or Contact us 04298 278595,  8903050494. .
          </p>
          <Link to="/">
            <button className="bg-blue-500 rounded-md cursor-pointer hover:bg-black active:bg-gray-900 px-4 md:px-8 py-2 font-semibold text-base md:text-lg text-gray-200 hover:text-white duration-300 mt-4">
              back to Home
            </button>
          </Link>
        </div>
      </motion.div>
     
    </div>
  );
  
};

export default GoldLoanSuccess;
