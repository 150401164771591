import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="container mx-auto py-8 px-4">
      <h1 className="text-3xl font-bold mb-6">Privacy Policy</h1>

      <p>
        This Privacy Policy describes how we collects,
        uses, and shares personal information when you use our website or make a purchase from us.
      </p>

      <div className="mt-6">
        <h2 className="text-2xl font-bold mb-4">Information We Collect</h2>

        <p>
          When you visit our website, we automatically collect certain information about your device,
          including information about your web browser, IP address, time zone, and some of the cookies
          that are installed on your device. Additionally, as you browse our website, we collect information
          about the individual web pages or products that you view, what websites or search terms referred
          you to our website, and information about how you interact with our website.
        </p>
      </div>

      <div className="mt-6">
        <h2 className="text-2xl font-bold mb-4">Information You Provide</h2>

        <p>
          When you make a purchase or attempt to make a purchase through our website, we collect certain
          information from you, including your name, billing address, shipping address, payment information
          (including credit card numbers), email address, and phone number.
        </p>
      </div>

      <div className="mt-6">
        <h2 className="text-2xl font-bold mb-4">How We Use Your Information</h2>

        <p>
          We use the information we collect to fulfill any orders placed through our website (including
          processing your payment information, arranging for shipping, and providing you with invoices and/or
          order confirmations). Additionally, we use this information to:
        </p>

        <ul className="list-disc pl-6 mt-3">
          <li>Communicate with you;</li>
          <li>Screen our orders for potential risk or fraud; and</li>
          <li>Provide you with information or advertising relating to our products or services.</li>
        </ul>
      </div>
      <div className="mt-6">
        <h2 className="text-2xl font-bold mb-4">Privacy Policy for Chit Scheme</h2>

        <ul className="list-disc pl-6 mt-3">
          <li>Customers monthly pay Rs. 1250/- Rs.2500/- Rs.5000 as per plan;</li>
          <li>Pay installment every month between 1-5 date; </li>
          <li>After completing 12 installments, we provide a KDM 916 gold coin as per the scheme;</li>
          <li>If any customers not pay correctly 12 installments, they will get total paid amount at the end of 12th month with deduction of 1 month installment for member fee;</li>
          <li>If customers continuously not pay two installments as per company policy customer disqualified from the chit scheme;</li>
          <li>After successful completion of 12th installment, then customer get the gold coin or silver coin with in 30 days. correct grammar; </li>

        </ul>
      </div>

      {/* Add similar styling for other sections... */}

      <div className="mt-6">
        <h2 className="text-2xl font-bold mb-4">Contact Us</h2>

        <p>
          For more information about our privacy practices, if you have questions, or if you would like to make a complaint,
          please contact us by email at <strong>gurugugangroup@gmail.com.</strong> 
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
