// EmailOTPValidation.js
import React, { useState } from "react";
import { Link } from "react-router-dom";
import ShowAlert from "../../components/general/ShowAlert";
import { validateEmailOTP } from '../../api/Api'; // Assuming you have an API function for OTP validation

const EmailOTPValidation = ({ email, clientName }) => {
  const [otp, setOtp] = useState("");
  const [errOTP, setErrOTP] = useState("");

  const handleOtpChange = (e) => {
    setOtp(e.target.value);
    setErrOTP("");
  };

  const handleVerifyOTP = () => {
    // Validate OTP logic here
    if (!otp) {
      setErrOTP("Enter the OTP");
    } else {
      // Call API to validate OTP
      const otpValidationRequest = {
        email: email,
        otp: otp,
      };

      validateEmailOTP(otpValidationRequest)
        .then((res) => {
          // Handle successful OTP validation
          ShowAlert(res.message, "success");
          // You can perform additional actions after successful OTP validation
        })
        .catch((error) => {
          // Handle OTP validation error
          ShowAlert("Invalid OTP. Please try again!", "error");
          console.error(error);
        });
    }
  };

  return (
    <div className="max-w-md mx-auto mt-20 p-6 bg-white rounded-md shadow-md">
      <h2 className="text-2xl font-bold mb-4">Email OTP Verification</h2>
      <p>
        Hello dear {clientName}, We sent an OTP to {email}. Please enter the
        OTP to complete the signup process.
      </p>
      <input
        type="text"
        value={otp}
        onChange={handleOtpChange}
        placeholder="Enter OTP"
        className="w-full mt-4 p-2 border border-gray-300 rounded-md"
      />
      {errOTP && <p className="text-red-500 mt-2">{errOTP}</p>}
      <button
        onClick={handleVerifyOTP}
        className="w-full mt-4 p-2 bg-blue-500 text-white rounded-md hover:bg-blue-600 focus:outline-none focus:ring focus:border-blue-300"
      >
        Verify OTP
      </button>
      <p className="mt-4 text-sm">
        Didn't receive the OTP?{" "}
        <Link to="/resendotp" className="text-blue-500">
          Resend OTP
        </Link>
      </p>
    </div>
  );
};

export default EmailOTPValidation;
