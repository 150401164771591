// SilverPrice.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';

const SilverPrice = () => {
  const [silverPrice, setSilverPrice] = useState(null);

  useEffect(() => {
    const fetchSilverPrice = async () => {
      try {
        // Replace 'YOUR_API_KEY' with your actual API key
        const apiKey = 'r6m3kbruijx1b74omv3b9460xyki1or4sb22qzlexktqf733vj75bp5fqtj7';
        const apiUrl = 'https://metals-api.com/api/latest?access_key=r6m3kbruijx1b74omv3b9460xyki1or4sb22qzlexktqf733vj75bp5fqtj7';

        const response = await axios.get(apiUrl, {
          headers: {
            'x-access-token': apiKey,
          },
        });

        const { data } = response;

        if (data && data.success) {
          setSilverPrice(data.price.toFixed(2));
        } else {
          console.error('Error fetching silver price');
        }
      } catch (error) {
        console.error('Error fetching silver price', error);
      }
    };

    fetchSilverPrice();
  }, []);

  return (
    <div>
      <h1>Today's Live Silver Price</h1>
      {silverPrice !== null ? (
        <p>Silver Price: RS.{silverPrice} Rupees</p>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
};

export default SilverPrice;
