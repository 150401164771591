import * as React from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Page,
  Toolbar,
  Edit,
  Inject,
} from "@syncfusion/ej2-react-grids";
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

import "./grid.css";
import { toast, Toast } from "react-hot-toast";
import {
  getGvgData,
  getGvgOrder,
  retrieveGvgOrder,
} from "../../api/PupilApi";

function AdminViewProductList() {
  const navigate = useNavigate();
  const location = useLocation();
  const { name, email, productList } = location.state; // constrcut as planData
  //alert("name"+JSON.stringify(name));
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const toolbarOptions = ["Add", "Edit", "Delete"];

  const validationRules = { required: true };
  const orderidRules = { required: true, number: true };
  const pageSettings = { pageCount: 5 };
  
  return (
    <div className="control-pane flex-1 m-5">
      <div className="control-section">
      <div className="flex items-center mb-3">
        <h2 className="name pr-3 font-medium">Name:<span className="bg-dark font-semibold">{name}</span></h2>
        <Link
        to={{
          pathname: "/viewOrderList",
        }}
        state={{
          name: name,
          email: email,
        }}
        exact 
        >
        <button
          className="select-none rounded-lg float-right bg-black text-white py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase shadow-md shadow-pink-500/20 transition-all hover:shadow-lg hover:shadow-pink-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
        >
          <span>back</span>
        </button>
        </Link>
       
        </div>
        <GridComponent
          dataSource={productList}
          toolbar={toolbarOptions}
          allowPaging={true}
          pageSettings={pageSettings}
        >
          <ColumnsDirective>
            <ColumnDirective
              field="_id"
              headerText="Product Id"
              width="150"
              textAlign="Center"
            ></ColumnDirective>
            <ColumnDirective
              field="category"
              headerText="Category"
              width="150"
              textAlign="Center"
              isPrimaryKey={true}
            ></ColumnDirective>
            <ColumnDirective
              field="size"
              headerText="Size"
              width="150"
              textAlign="Center"
            ></ColumnDirective>
            <ColumnDirective
              field="weight"
              headerText="Weight"
              width="150"
              textAlign="Center"
            ></ColumnDirective>
            <ColumnDirective
              field="badge"
              headerText="Badge"
              width="150"
              textAlign="Center"
            ></ColumnDirective>
            <ColumnDirective
              field="color"
              headerText="Color"
              width="150"
              textAlign="Center"
            ></ColumnDirective>
            <ColumnDirective
              field="des"
              headerText="Description"
              width="150"
              textAlign="Center"
            ></ColumnDirective>
            <ColumnDirective
              field="price"
              headerText="Price"
              width="150"
              textAlign="Center"
            ></ColumnDirective>
            <ColumnDirective
              field="productName"
              headerText="Product Name"
              width="150"
              textAlign="Center"
            ></ColumnDirective>
            <ColumnDirective
              field="quantity"
              headerText="Quantity"
              width="150"
              textAlign="Center"
            ></ColumnDirective>
          </ColumnsDirective>
          <Inject services={[Page, Toolbar, Edit]} />
        </GridComponent>
      </div>
    </div>
  );
}

export default AdminViewProductList;
