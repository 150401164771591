import React from "react";
import Heading from "../Products/Heading";
import Product from "../Products/Product";
import anklet1 from "../../../assets/images/products/bestSeller/anklet1.webp"
import anklet2 from "../../../assets/images/products/bestSeller/anklet2.jpg"
import anklet3 from "../../../assets/images/products/bestSeller/anklet3.jpg"
import anklet4 from "../../../assets/images/products/bestSeller/anklet4.jpg"
import bracelet1 from "../../../assets/images/products/bestSeller/bracelet1.webp"
import bracelet3 from "../../../assets/images/products/bestSeller/bracelet3.webp"
import bracelet4 from "../../../assets/images/products/bestSeller/bracelet4.webp"
import bracelet5 from "../../../assets/images/products/bestSeller/bracelet5.webp"
import toeRing2 from "../../../assets/images/products/bestSeller/toeRing2.webp"
import toeRing3 from "../../../assets/images/products/bestSeller/toeRing3.webp"
import toeRing4 from "../../../assets/images/products/bestSeller/toeRing4.webp"
import toeRing5 from "../../../assets/images/products/bestSeller/toeRing5.webp"

const BestSellers = () => {
  return (
    <div className="w-full pb-20">
      <Heading heading="Anklets" />
      <div className="w-full grid grid-cols-1 md:grid-cols-2 lgl:grid-cols-3 xl:grid-cols-4 gap-10">
        <Product
          _id="1011"
          img={anklet1}
          category="Anklets"
          productName="Anklet1"
          price="35.00"
          color="White"
          size="4 inch"
          weight ="74 gram"
          badge={true}
          des=" Ladies Silver Anklet, Designer Polished Ladies Silver Anklet, Polished Ladies Silver Anklet, Fancy Ladies Silver Anklet, Decorative Ladies Silver Anklet and Ladies Silver Anklet from GVG."
        />
        <Product
          _id="1012"
          img={anklet2}
          category="Anklets"
          productName="Anklet2"
          price="180.00"
          color="Gray"
          size="8 inch"
          weight ="56 gram"
          badge={false}
          des="MOONPLUS Silver Enamel Design Anklet/Payal/Kolusu for Women.
          Stylish and trendy, this pair of silver anklets/payal/kolusu is suitable for girls and womens.
          Size : 10 inches length , Approximately weight:90grams to 95grams
These silver payals for women have been specially designed to be worn on wedding occasions, marriage functions and festive parties and gatherings and also regular use.
Find and verify your size using the size chart before your order.
For more Details like cleaning instructions - Read Product Description.
Additional Information
ManufacturerWomen,GVG Groups, Mecheri, Salem, Tamilnadu
Item Weight : 90 grams to 95 grams
Generic NameAnklets
"
        />
        <Product
          _id="1013"
          img={anklet3}
          category="Anklets"
          productName="Anklet3"
          price="25.00"
          color="Mixed"
          size="9 inch"
          weight ="54 gram"
          badge={true}
          des="Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis."
        />
        <Product
          _id="1014"
          img={anklet4}
          category="Anklets"
          productName="Anklet4"
          price="220.00"
          color="Black"
          size="8.5 inch"
          weight ="67 gram"
          badge={false}
          des="Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis."
        />
    
        
        
        
      </div>
     
      <Heading heading="Bracelets" />
      <div className="w-full grid grid-cols-1 md:grid-cols-2 lgl:grid-cols-3 xl:grid-cols-4 gap-10">
        <Product
          _id="1011"
          img={bracelet1}
          category="Bracelets"
          productName="Bracelet1"
          price="35.00"
          color="White"
          size="8.5 inch"
          weight ="74 gram"
          badge={true}
          des=" Ladies Silver Anklet, Designer Polished Ladies Silver Anklet, Polished Ladies Silver Anklet, Fancy Ladies Silver Anklet, Decorative Ladies Silver Anklet and Ladies Silver Anklet from GVG."
        />
   
         <Product
          _id="1013"
          img={bracelet3}
          category="Bracelets"
          productName="Bracelet3"
          price="35.00"
          color="White"
          size="6.5 inch"
          weight ="34 gram"
          badge={true}
          des=" Ladies Silver Anklet, Designer Polished Ladies Silver Anklet, Polished Ladies Silver Anklet, Fancy Ladies Silver Anklet, Decorative Ladies Silver Anklet and Ladies Silver Anklet from GVG."
        />
        <Product
          _id="1014"
          img={bracelet4}
          category="Bracelets"
          productName="Bracelet4"
          price="220.00"
          color="Black"
          size="8 inch"
          weight ="39 gram"
          badge={false}
          des="Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis."
        />
         <Product
          _id="1014"
          img={bracelet5}
          category="Bracelets"
          productName="Bracelet5"
          price="220.00"
          color="Black"
          size="4 inch"
          weight ="56 gram"
          badge={false}
          des="Lorem ipsum dolor sit amet consectetur adipisicing elit. Hic excepturi quibusdam odio deleniti reprehenderit facilis."
        />
         
         
        
      </div>
      <Heading heading="Toe Ring" />
      <div className="w-full grid grid-cols-1 md:grid-cols-2 lgl:grid-cols-3 xl:grid-cols-4 gap-10">
        
         <Product
          _id="1011"
          img={toeRing2}
          category="Toe Rings"
          productName="Toe Ring1"
          price="35.00"
          color="White"
          size="4 inch"
          weight ="74 gram"
          badge={true}
          des=" Ladies Silver Anklet, Designer Polished Ladies Silver Anklet, Polished Ladies Silver Anklet, Fancy Ladies Silver Anklet, Decorative Ladies Silver Anklet and Ladies Silver Anklet from GVG."
        />
         <Product
          _id="1011"
          img={toeRing3}
          category="Toe Rings"
          productName="Toe Ring2"
          price="35.00"
          color="White"
          size="3 inch"
          weight ="72 gram"
          badge={true}
          des=" Ladies Silver Anklet, Designer Polished Ladies Silver Anklet, Polished Ladies Silver Anklet, Fancy Ladies Silver Anklet, Decorative Ladies Silver Anklet and Ladies Silver Anklet from GVG."
        />
         <Product
          _id="1011"
          img={toeRing4}
          category="Toe Rings"
          productName="Toe Ring3"
          price="35.00"
          color="White"
          size="4 inch"
          weight ="79 gram"
          badge={true}
          des=" Ladies Silver Anklet, Designer Polished Ladies Silver Anklet, Polished Ladies Silver Anklet, Fancy Ladies Silver Anklet, Decorative Ladies Silver Anklet and Ladies Silver Anklet from GVG."
        />
         <Product
          _id="1011"
          img={toeRing5}
          category="Toe Rings"
          productName="Toe Ring4"
          price="35.00"
          color="White"
          size="3.5 inch"
          weight ="87 gram"
          badge={true}
          des=" Ladies Silver Anklet, Designer Polished Ladies Silver Anklet, Polished Ladies Silver Anklet, Fancy Ladies Silver Anklet, Decorative Ladies Silver Anklet and Ladies Silver Anklet from GVG."
        />
    
    </div>
    </div>
    
  );
};

export default BestSellers;
