import React, { useState, useEffect } from "react";
import { useContextSelector } from "use-context-selector";
import { StateContext } from "../../contexts/StateContext";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from 'react-router-dom';
import { IoClose, IoCloudDownload } from "react-icons/io5";
import { updatePaymentStatusBackend } from "../../api/FinanceApi";

const UpdatePaymentStatus = ({ paymentInvoiceId,name,email,planCost,planId }) => {
  const navigate = useNavigate();
   
  const currentColor = useContextSelector(
    StateContext,
    (state) => state.currentColor
  );
  const currentMode = useContextSelector(
    StateContext,
    (state) => state.currentMode
  );
  const [invoiceStatusRequest, setInvoiceStatusRequest] = useState({
    userName: email,
    planId: planId,
    invoiceStatus: "",
    comments:"",
    paymentId: paymentInvoiceId,
    transactionId:"",
    amountCredited:"",
    paymentDate:"",
    invoiceDate:"",
    invoiceDateChange:"no",
    planCost:"",
    
  });
  const [showModal, setShowModal] = useState(true);
  const [showLoading, setShowLoading] = useState(false);
  const clearAlert = () => {
    toast.success("");
    toast.error("");
  };
  useEffect(() => {
    document.title = "update payment existing subscription for status";
    alert("I am from useEffect"+JSON.stringify(invoiceStatusRequest))
   // updateDataFromSubscription();
  }, []);

  const updateTransactionId = (event) => {
    setInvoiceStatusRequest({
      ...invoiceStatusRequest,
      transactionId: event.target.value,
    });
  };
  const updateInvoiceDateOption = (event) => {
    setInvoiceStatusRequest({
      ...invoiceStatusRequest,
      invoiceDateChange: event.target.value,
    });
  };
  
  const updateInvoiceDate = (event) => {
    setInvoiceStatusRequest({
      ...invoiceStatusRequest,
      invoiceDate: event.target.value,
    });
  };
  const updateTotalBillingDay = (event) => {
    setInvoiceStatusRequest({
      ...invoiceStatusRequest,
     // totalBillingDay: event.target.value?event.target.value:totalBillingDay,
    });
  };
  const updateplanCost = (event) => {
    setInvoiceStatusRequest({
      ...invoiceStatusRequest,
      planCost: event.target.value?event.target.value:planCost,
    });
  };
  
  const updateAmountCredited = (event) => {
    setInvoiceStatusRequest({
      ...invoiceStatusRequest,
      amountCredited: event.target.value,
    });
    
  };
  
  const updatePaymentDate = (event) => {
    setInvoiceStatusRequest({
      ...invoiceStatusRequest,
      paymentDate: event.target.value,
    });
  };
  const updateUserName = (event) => {
    setInvoiceStatusRequest({
      ...invoiceStatusRequest,
      userName: email,
    });
  };

  const updateInvoiceStatus = (event) => {
    setInvoiceStatusRequest({
      ...invoiceStatusRequest,
      invoiceStatus: event.target.value,
    });
  };

  const updateplanId = (event) => {
    setInvoiceStatusRequest({
      ...invoiceStatusRequest,
      planId: planId,
    });
  };
  const updatePaymentId = (event) => {
    setInvoiceStatusRequest({
      ...invoiceStatusRequest,
      //paymentId: paymentId,
    });
  };
  const updateComments = (event) => {
    setInvoiceStatusRequest({
      ...invoiceStatusRequest,
      comments: event.target.value,
    });
  };
  const handleClickBack = () => {
    navigate('/');
  };
  
  
  const updatePaymentStatus = (event) => {
    event.preventDefault();
    setShowLoading(true);
    updatePaymentStatusBackend(invoiceStatusRequest)
      .then((res) => {
        //alert("suucesss"+JSON.stringify(res.data.message));
        setShowModal(false);
        toast.success(res.data.message); setInvoiceStatusRequest({ ...invoiceStatusRequest, userName: "" });
        setInvoiceStatusRequest({ ...invoiceStatusRequest, invoiceDate: "" });
        setInvoiceStatusRequest({ ...invoiceStatusRequest, planId: "" });
       
        setInvoiceStatusRequest({ ...invoiceStatusRequest, paymentId: "" });
        setInvoiceStatusRequest({ ...invoiceStatusRequest, invoiceStatus: "" });
        setInvoiceStatusRequest({
          ...invoiceStatusRequest,
          comments: "",
        });
        setInvoiceStatusRequest({
          ...invoiceStatusRequest,
          transactionId: "",
        });
        setInvoiceStatusRequest({
          ...invoiceStatusRequest,
          amountCredited: "",
        });
        setInvoiceStatusRequest({
          ...invoiceStatusRequest,
          paymentDate: "",
        });
        setInvoiceStatusRequest({ ...invoiceStatusRequest, totalBillingDay: "" });
      
        setShowLoading(false);
        navigate('/');
        navigate('/adminDashboard');
      })
      .catch((error) => {
        if (error.status === 401) {
          toast.error(
            "Your username or email does not exist. Please try again !"
          );
        } else {
          toast.error(error.message);
        }
        setShowLoading(false);
      });
  };
  
  
  

  return (
    <div>
   
      {showModal ? (
        <>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-6 mx-auto">
              <div className="border-0 px-3 mt-16 rounded-lg shadow-lg relative flex flex-col w-100 bg-gray-200 outline-none focus:outline-none">
                <div className="flex items-start justify-between pt-2 px-2 border-b border-solid border-blueGray-200 rounded-t">
                  <button
                    className="p-1 ml-auto bg-white border-0 text-red  float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowModal(false)}
                  >
                    <span className="bg-white text-red  h-6 w-6 text-2xl ">
                      <IoClose />
                    </span>
                  </button>
                </div>
                <h2 className="card-title text-center text-lg font-semibold">
                  Update Invoice Payment Status
                </h2>
                <div className="relative p-0 px-3 flex-none">
                <form onSubmit={updatePaymentStatus}>
              <div className="grid md:grid-cols-2">
               
                
                
                <div className="mt-2 mx-3">
                  <label className="float-left items-center flex mt-2" for="subscriptionType">
                    Invoice status
                  </label>
                  <select
                    className="w-100 py-2 px-6 text-gray-700 bg-gray-200 focus:outline-none focus:bg-white border border-gray-300 focus:border-blue-500"
                    name="option"
                    onChange={(event) => updateInvoiceStatus(event)}
                  >
                    <option
                      className="w-100 py-2 px-6 text-gray-900 bg-gray-200 focus:outline-none focus:bg-white border border-gray-300 focus:border-blue-500"
                      value="empty"
                    ></option>
                    <option value="credited">Credited</option>
                    <option value="modified">Modify</option>
                   
                  </select>
                </div>
                <div className="mt-2 mx-3">
                  <label className="float-left items-center flex mt-2" for="comments">
                    Comments
                  </label>
                  <input
                    type="text"
                    id="comments"
                    className="w-100 py-2 px-6 text-gray-700 bg-gray-200 focus:outline-none focus:bg-white border border-gray-300 focus:border-blue-500"
                    placeholder="Please provide your comments"
                    required
                    onChange={updateComments}
                    autoComplete="on"
                  />
                </div>
                {(invoiceStatusRequest.invoiceStatus==="credited")?(
                  <><div className="mt-2 mx-3">
                          <label className="float-left items-center flex mt-2" for="transactionId">
                           transactionId
                          </label>
                          <input
                            type="text"
                            id="transactionId"
                            className="w-100 py-2 px-6 text-gray-700 bg-gray-200 focus:outline-none focus:bg-white border border-gray-300 focus:border-blue-500"
                            placeholder="Please enter transactionId"
                            required
                            onChange={updateTransactionId}
                            autoComplete="on" />
                        </div><div className="mt-2 mx-3">
                            <label className="float-left items-center flex mt-2" for="amountCredited">
                              Amount credited
                            </label>
                            <input
                              type="text"
                              id="amountCredited"
                              className="w-100 py-2 px-6 text-gray-700 bg-gray-200 focus:outline-none focus:bg-white border border-gray-300 focus:border-blue-500"
                              placeholder="Please enter the amount"
                              required
                              onChange={updateAmountCredited}
                              autoComplete="on" />
                          </div><div className="mt-2 mx-3">
                            <label className="float-left items-center flex mt-2" for="paymentDate">
                              Payment Date
                            </label>
                            <input
                              type="date"
                              id="paymentDate"
                              className="w-100 py-2 px-6 text-gray-700 bg-gray-200 focus:outline-none focus:bg-white border border-gray-300 focus:border-blue-500"
                              placeholder="Please provide your comments"
                              required
                              onChange={updatePaymentDate}
                              autoComplete="on" />
                          </div></>
                ):(null)}
                {(invoiceStatusRequest.invoiceStatus==="pending")?(
                  <>
                        <div className="mt-2 mx-3">
                          <label className="float-left items-center flex mt-2" for="transactionId">
                           planCost ({planCost})
                          </label>
                          <input
                            type="text"
                            id="planCost"
                            className="w-100 py-2 px-6 text-gray-700 bg-gray-200 focus:outline-none focus:bg-white border border-gray-300 focus:border-blue-500"
                            placeholder="Please enter planCost"
                            required
                            onChange={updateplanCost}
                            autoComplete="on" />
                        </div>
                        <div className="mt-2 mx-3">
                  <label className="float-left items-center flex mt-2" for="subscriptionType">
                    Do you want to change Invoice Date?
                  </label>
                  <select
                    className="w-100 py-2 px-6 text-gray-700 bg-gray-200 focus:outline-none focus:bg-white border border-gray-300 focus:border-blue-500"
                    name="option"
                    onChange={(event) => updateInvoiceDateOption(event)}
                  >
                    <option
                      className="w-100 py-2 px-6 text-gray-900 bg-gray-200 focus:outline-none focus:bg-white border border-gray-300 focus:border-blue-500"
                      value="empty"
                    ></option>
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </select>
                </div>
                        {(invoiceStatusRequest.invoiceDateChange==="yes")?(
                  <>
                        <div className="mt-2 mx-3">
                          <label className="float-left items-center flex mt-2" for="transactionId">
                           invoice Approved Date
                          </label>
                          <input
                            type="date"
                            id="transactionId"
                            className="w-100 py-2 px-6 text-gray-700 bg-gray-200 focus:outline-none focus:bg-white border border-gray-300 focus:border-blue-500"
                            placeholder="Please enter transactionId"
                            required
                            onChange={updateInvoiceDate}
                            autoComplete="on" />
                        </div>
                        </>
                ):(null)}
                        </>
                ):(null)}
               
                <br />
                <br />
                <br />
                
              </div>
              <button
                  
                  className="select-none rounded-lg py-3 px-6 text-center align-middle font-sans text-xs font-bold uppercase shadow-md shadow-pink-500/20 transition-all hover:shadow-lg hover:shadow-pink-500/40 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                     
                  style={{ backgroundColor: currentColor }}
                >
                  <span>Send</span>
                </button>
                <br />
                <br />
            </form>
                 
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : (    
       null
      )}

      <Toaster
        position="bottom-right"
        reverseOrder={false}
        toastOptions={{
          error: {
            duration: 6000,
            style: {
              background: "#FF4136",
              color: "#fff",
            },
          },

          success: {
            duration: 6000,
            style: {
              background: "#008000",
              color: "#fff",
            },
          },
        }}
      />
    </div>
  );
};

export default UpdatePaymentStatus;
