import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Breadcrumbs from "../../components/pageProps/Breadcrumbs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt, faPhone, faMobile } from "@fortawesome/free-solid-svg-icons";

const About = () => {
  const location = useLocation();
  const [prevLocation, setPrevLocation] = useState("");

  useEffect(() => {
    setPrevLocation(location.state.data);
  }, [location]);

  return (
    <div className="max-w-container mx-auto px-4 flex flex-col items-center">
      <Breadcrumbs title="About" prevLocation={prevLocation} />
      <div className="pb-10 text-center">
        <h1 className="max-w-[600px] text-left text-lightText font-semibold mb-4">
        <FontAwesomeIcon icon={faMapMarkerAlt} className="mr-2" />
          <span className="text-primeColor">Address:</span>
        </h1>
        <div className="mb-4 text-left">
          <p className="flex items-center mb-2">

            33/A8, A9<br />Sree Devi Complex,<br />Omaluran Street,<br />Vijaya Mahal Near,<br />Mecheri,<br />Salem Dt<br />Tamilnadu, India - 636453
          </p>
          <p className="flex items-center mb-2">
            <FontAwesomeIcon icon={faMobile} className="mr-2" />
            Mobile: 8903050494
          </p>
          <p className="flex items-center">
            <FontAwesomeIcon icon={faPhone} className="mr-2" />
            Phone: 04298 278595
          </p>
        </div>

        <Link to="/shop">
          <button className="w-52 h-10 bg-blue-500 text-white hover:bg-black duration-300">
            Continue Shopping
          </button>
        </Link>
      </div>
    </div>
  );
};

export default About;
